import React, { useState } from "react";
import {
  PhoneIcon,
  PhoneArrowDownLeftIcon,
  PhoneArrowUpRightIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import Loading from "../shared/loading";
import { useDashboardStats } from "@/services/CallsApiClient";
import { format } from "date-fns";
import { defaultCallsModel } from "@/services/mock";
import { DateStringFormat } from "@/types/callbotRecord";
import { type DatePickerFilter } from "@/components/shared/customDateRangePicker/types";

interface Props {
  selectedDateRange: DatePickerFilter;
}

function Calls({ selectedDateRange }: Props): React.JSX.Element {
  const [calls, setCalls] = useState(defaultCallsModel);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const {
    isLoading,
    isError,
    data: response,
    status,
  } = useDashboardStats(
    format(selectedDateRange.startDate, DateStringFormat),
    format(selectedDateRange.endDate, DateStringFormat)
  );

  React.useEffect(() => {
    if (status === "success") {
      const { data } = response;
      const calls = data.data.calls;
      setCalls(calls);
      setIsDataLoading(false);
    }

    if (isLoading) {
      setIsDataLoading(true);
    }

    if (isError) {
      throw new Error("Error fetching data");
    }
  }, [isError, isLoading, response, status]);

  return (
    <div className="w-full rounded-md bg-slate-100 p-8">
      <div className="flex flex-row justify-between rounded-xl pb-3">
        <p className="tracking-wid mb-4 text-xl font-semibold">Callbot</p>
      </div>
      {isDataLoading ? (
        <div className="relative h-[345px] w-full rounded-md bg-slate-100 p-8">
          <Loading />
        </div>
      ) : (
        <div className="2xl:gap-7.5 grid grid-cols-2 gap-4 xs:grid-cols-1 sm:grid-cols-2  md:grid-cols-2 md:gap-6 lg:grid-cols-2">
          <div
            className="border-stroke px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark flex flex-col items-center rounded-sm border bg-white py-6"
            data-testid="dashboard-total-calls"
          >
            <div className="flex h-11 w-11 items-center justify-center rounded-full bg-dash-icon-bg">
              <PhoneIcon className="h-6 w-6 text-trajector-blue" />
            </div>

            <div className="mt-4 flex flex-col items-center justify-between">
              <h4 className="text-2xl font-bold text-black dark:text-white">
                {calls["Total Calls"].toLocaleString()}
              </h4>
              <span className="text-center text-sm font-medium text-slate-500">
                Total Calls
              </span>
            </div>
          </div>
          <div
            data-testid="dashboard-invoice-opportunity"
            className="border-stroke px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark flex flex-col items-center rounded-sm border bg-white py-6"
          >
            <div className="flex h-11 w-11 items-center justify-center rounded-full bg-dash-icon-bg">
              <CheckCircleIcon className="h-6 w-6 text-trajector-blue" />
            </div>

            <div className="mt-4 flex flex-col items-center justify-between">
              <h4 className="text-2xl font-bold text-black dark:text-white">
                {calls["Invoice Opportunity Created"].toLocaleString()}
              </h4>
              <span className="text-center text-sm font-medium text-slate-500">
                Invoice Opportunity Created
              </span>
            </div>
          </div>
          <div
            data-testid="dashboard-failed"
            className="border-stroke px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark flex flex-col items-center rounded-sm border bg-white py-6"
          >
            <div className="flex h-11 w-11 items-center justify-center rounded-full bg-dash-icon-bg">
              <ExclamationCircleIcon className="h-6 w-6 text-trajector-blue" />
            </div>

            <div className="mt-4 flex flex-col items-center justify-between">
              <h4 className="text-2xl font-bold text-black dark:text-white">
                {calls.Failed.toLocaleString()}
              </h4>
              <span className="text-center text-sm font-medium text-slate-500">
                Failed
              </span>
            </div>
          </div>
          <div
            data-testid="dashboard-benefit-threshold"
            className="border-stroke px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark flex flex-col items-center rounded-sm border bg-white py-6"
          >
            <div className="flex h-11 w-11 items-center justify-center rounded-full bg-dash-icon-bg">
              <XCircleIcon className="h-6 w-6 text-trajector-blue" />
            </div>

            <div className="mt-4 flex flex-col items-center justify-between">
              <h4 className="text-2xl font-bold text-black dark:text-white">
                {calls["Baseline Not Met"].toLocaleString()}
              </h4>
              <span className="text-center text-sm font-medium text-slate-500">
                Benefit Threshold Not Met
              </span>
            </div>
          </div><div
            data-testid="dashboard-queued"
            className="border-stroke px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark flex flex-col items-center rounded-sm border bg-white py-6"
          >
            <div className="flex h-11 w-11 items-center justify-center rounded-full bg-dash-icon-bg">
              <PhoneArrowDownLeftIcon className="h-6 w-6 text-trajector-blue" />
            </div>

            <div className="mt-4 flex flex-col items-center justify-between">
              <h4 className="text-2xl font-bold text-black dark:text-white">
                {calls["Queued"].toLocaleString()}
              </h4>
              <span className="text-center text-sm font-medium text-slate-500">
                Queued
              </span>
            </div>
          </div><div
            data-testid="dashboard-in-progress"
            className="border-stroke px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark flex flex-col items-center rounded-sm border bg-white py-6"
          >
            <div className="flex h-11 w-11 items-center justify-center rounded-full bg-dash-icon-bg">
              <PhoneArrowUpRightIcon className="h-6 w-6 text-trajector-blue" />
            </div>

            <div className="mt-4 flex flex-col items-center justify-between">
              <h4 className="text-2xl font-bold text-black dark:text-white">
                {calls["In Progress"].toLocaleString()}
              </h4>
              <span className="text-center text-sm font-medium text-slate-500">
                In Progress
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default Calls;
