import React, { useState } from "react";
import {
  RectangleStackIcon,
  DocumentTextIcon,
  ClipboardIcon,
  ClockIcon,
  CheckCircleIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import Loading from "../shared/loading";
import { useDashboardStats } from "@/services/CallsApiClient";
import { format } from "date-fns";
import { defaultTasksModel } from "@/services/mock";
import { type DatePickerFilter } from "@/components/shared/customDateRangePicker/types";
import { useRouter } from "next/router";
import { DateStringFormat } from "@/types/callbotRecord";

const taskObjs = [
  {
    label: "Total",
    key: "totalTasks",
    icon: <RectangleStackIcon className="h-6 w-6 text-trajector-blue" />,
  },
  {
    label: "Created",
    key: "created",
    executionStatus: "READY_TO_REVIEW",
    icon: <ClipboardIcon className="h-6 w-6 text-trajector-blue" />,
  },
  {
    label: "Ready for Review",
    key: "ready_for_review",
    executionStatus: "READY_TO_REVIEW",
    icon: <ClockIcon className="h-6 w-6 text-trajector-blue" />,
  },
  {
    label: "Review In Progress",
    key: "review_in_progress",
    executionStatus: "READY_TO_REVIEW",
    icon: <UserCircleIcon className="h-6 w-6 text-trajector-blue" />,
  },
  {
    label: "Closed",
    key: "closed",
    executionStatus: "ARCHIVED",
    icon: <CheckCircleIcon className="h-6 w-6 text-trajector-blue" />,
  },
  {
    label: "Invoice Pending",
    key: "invoice_pending",
    executionStatus: "ARCHIVED",
    icon: <DocumentTextIcon className="h-6 w-6 text-trajector-blue" />,
  },
];

interface Props {
  selectedDateRange: DatePickerFilter;
}

function Tasks({ selectedDateRange }: Props): React.JSX.Element {
  const { push } = useRouter();
  const [tasks, setTasks] = useState(defaultTasksModel);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const {
    isLoading,
    isError,
    data: response,
    status,
  } = useDashboardStats(
    format(selectedDateRange.startDate, DateStringFormat),
    format(selectedDateRange.endDate, DateStringFormat)
  );

  React.useEffect(() => {
    if (status === "success") {
      const { data } = response;
      const tasks = data.data.tasks.counters.status;
      setTasks(tasks);
      setIsDataLoading(false);
    }

    if (isLoading) {
      setIsDataLoading(true);
    }

    if (isError) {
      throw new Error("Error fetching data");
    }
  }, [isError, isLoading, response, status]);

  const sumKeysOfJsonObject = (jsonObject: Record<string, unknown>) => {
    let sum = 0;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    Object.keys(jsonObject).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
      const value = jsonObject[key];

      if (typeof value === "number") {
        sum += value;
      } else if (!isNaN(Number(value))) {
        sum += Number(value);
      }
    });

    return sum;
  };

  const totalTasks = sumKeysOfJsonObject(tasks);
  const newTasks = { ...tasks, totalTasks };

  return (
    <div className="w-full rounded-md bg-slate-100 p-8">
      <div className="flex flex-row justify-between rounded-xl pb-3">
        <p className="tracking-wid mb-4 text-xl font-semibold">
          Invoicing Opportunities
        </p>
      </div>
      {isDataLoading ? (
        <div className="relative h-[345px] w-full rounded-md bg-slate-100 p-8">
          <Loading />
        </div>
      ) : (
        <div className="2xl:gap-7.5 grid grid-cols-3 gap-4 xs:grid-cols-1 sm:grid-cols-2  md:grid-cols-2 md:gap-6 lg:grid-cols-3 xl:grid-cols-6">
          {taskObjs.map((task) => (
            <div
              onClick={() => {
                if (task.key === "totalTasks") {
                  return;
                } else if (task.key === "created") {
                  void push({
                    pathname: "/unassigned",
                    query: {
                      status: task.key,
                      from: format(
                        selectedDateRange.startDate,
                        DateStringFormat
                      ),
                      to: format(selectedDateRange.endDate, DateStringFormat),
                    },
                  });
                  return;
                } else {
                  void push({
                    pathname: "/assigned",
                    query: {
                      status: task.key,
                      executionStatus: task.executionStatus,
                      from: format(
                        selectedDateRange.startDate,
                        DateStringFormat
                      ),
                      to: format(selectedDateRange.endDate, DateStringFormat),
                    },
                  });
                }
              }}
              key={task.key}
              className={`border-stroke px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark flex ${
                task.key === "totalTasks" ? "" : "cursor-pointer"
              } flex-col items-center rounded-sm border bg-white py-6`}
              data-testid={`dashboard-invoicing-${task.label}`}
            >
              <div className="flex h-11 w-11 items-center justify-center rounded-full bg-dash-icon-bg">
                {task.icon}
              </div>

              <div className="mt-4 flex flex-col items-center justify-between">
                <h4 className="text-2xl font-bold text-black dark:text-white">
                  {newTasks[task.key as keyof typeof newTasks]}
                </h4>
                {task.key !== "totalTasks" && (
                  <a className="text-center text-sm font-medium text-trajector-blue">
                    {task.label}
                  </a>
                )}
                {task.key === "totalTasks" && (
                  <span className="text-center text-sm font-medium">
                    {task.label}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Tasks;
