import React, { Suspense } from "react";
import Loading from "../shared/loading";
import Table from "@/components/shared/table/Table";
import { useDashboardStats } from "@/services/CallsApiClient";
import { useUserColumns } from "./user-definitions";
import { format } from "date-fns";
import { type DatePickerFilter } from "@/components/shared/customDateRangePicker/types";
import { type UserStats } from "@/types/dashboard";
import { DateStringFormat } from "@/types/callbotRecord";

interface Props {
  selectedDateRange: DatePickerFilter;
}

function Users({ selectedDateRange }: Props): JSX.Element {
  return (
    <div className="w-full rounded-md bg-slate-100 p-8">
      <div className="flex flex-row justify-between rounded-xl pb-3">
        <p className="tracking-wid mb-4 text-xl font-semibold">Users</p>
      </div>
      <Suspense
        fallback={
          <div className="relative h-[345px] w-full rounded-md bg-slate-100 p-8">
            <Loading />
          </div>
        }
      >
        <UserDashboardInner selectedDateRange={selectedDateRange} />
      </Suspense>
    </div>
  );
}

function UserDashboardInner({
  selectedDateRange,
}: Props): React.JSX.Element | null {
  const defaultUserColumns = useUserColumns(selectedDateRange);
  const { data: response } = useDashboardStats(
    format(selectedDateRange.startDate, DateStringFormat),
    format(selectedDateRange.endDate, DateStringFormat)
  );
  const memoizedColumns = React.useMemo(
    () => defaultUserColumns,
    [defaultUserColumns]
  );

  if (!response) {
    return null;
  }
  const { data } = response;
  const users_data = data.data.users;

  return (
    <div className="2xl:gap-7.5 grid grid-cols-1 gap-4 xs:grid-cols-1 md:gap-6">
      <Table<UserStats>
        data={users_data}
        columns={memoizedColumns}
        enablePinning
        hasGlobalFilterBar={false}
        pageSize={15}
        isSmallTable
      />
    </div>
  );
}

export default Users;
